import React, { useEffect } from 'react';
import Swiper from 'swiper';

interface QuoteAttributes {
  authorImage?: {
    data?: {
      attributes?: {
        url?: string;
      };
    };
  };
  authorQuote?: string;
  author?: string;
  authorOccupation?: string;
  highlight?: boolean;
}

interface Quote {
  attributes?: QuoteAttributes;
}

interface QuotesProps {
  testimonials: Quote[];
  highlight?: boolean;
}

const Quotes: React.FC<QuotesProps> = ({ testimonials, highlight }) => {
  useEffect(() => {
    const swiper = new Swiper('.swiper-quotes', {
      spaceBetween: 70,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      autoHeight: true,
    });
  }, []);

  return (
    <>
      {testimonials
        .filter(quote => !highlight || (highlight && quote.attributes?.highlight))
        .map((quote, index) => (
          <div className="swiper-slide" key={index}>
            <blockquote className="vrt-edubox__quote">
              {quote.attributes?.authorImage?.data && (
                <div className="vrt-edubox__quote-image">
                  <img
                    src={quote.attributes.authorImage.data.attributes.url}
                    alt=""
                  />
                </div>
              )}
              <div>
                <svg
                  width="126"
                  height="103"
                  viewBox="0 0 126 103"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M37.0844 0H56.9956C57.8252 0 58.4889 0.41266 58.9867 1.23798C59.6504 1.89824 59.8993 2.64103 59.7333 3.46635L46.7911 50.7572V99.5337C46.7911 100.359 46.4593 101.184 45.7956 102.01C45.1319 102.67 44.3022 103 43.3067 103H3.48444C2.65481 103 1.82519 102.67 0.995556 102.01C0.331852 101.184 0 100.359 0 99.5337V54.9663C0 53.8109 0.165926 52.6554 0.497778 51.5C0.82963 50.1795 1.32741 49.1066 1.99111 48.2812L31.6089 2.97115C32.2726 2.14583 33.1022 1.48558 34.0978 0.990385C35.0933 0.330128 36.0889 0 37.0844 0ZM103.538 0H123.449C124.279 0 124.942 0.41266 125.44 1.23798C125.938 1.89824 126.104 2.64103 125.938 3.46635L113.244 50.7572V99.5337C113.244 100.359 112.83 101.184 112 102.01C111.336 102.67 110.507 103 109.511 103H69.6889C68.8593 103 68.0296 102.67 67.2 102.01C66.5363 101.184 66.2044 100.359 66.2044 99.5337V54.9663C66.2044 53.8109 66.3704 52.6554 66.7022 51.5C67.0341 50.1795 67.5319 49.1066 68.1956 48.2812L97.8133 2.97115C98.477 2.14583 99.3067 1.48558 100.302 0.990385C101.298 0.330128 102.376 0 103.538 0Z"
                    fill="white"
                  />
                </svg>
                <div>{quote.attributes?.authorQuote}</div>
                <cite>
                  {quote.attributes?.author} -{' '}
                  <small>
                    {quote.attributes?.authorOccupation?.split(',')[0]}
                  </small>
                </cite>
              </div>
              <a
                className={'vrt-edubox__quote-more'}
                href={document.querySelector('[data-page="verklaringen"]')?.href}
              >
                Lees meer
              </a>
            </blockquote>
          </div>
        ))}
    </>
  );
};

export default Quotes;