import React, { useState } from 'react';
import Quotes from './Quotes';
import ReactMarkdown from 'react-markdown';

interface TestimonialAttributes {
  authorImage?: {
    data?: {
      attributes?: {
        formats?: {
          thumbnail?: {
            url?: string;
          };
          large?: {
            url?: string;
          };
          medium?: {
            url?: string;
          };
        };
      };
    };
  };
  image?: {
    data?: {
      attributes?: {
        formats?: {
          large?: {
            url?: string;
          };
          medium?: {
            url?: string;
          };
        };
      };
    };
  };
  title?: string;
  author?: string;
  authorOccupation?: string;
  authorQuote?: string;
  text?: string;
  youtube?: string;
}

interface Testimonial {
  id?: string;
  attributes?: TestimonialAttributes;
}

interface TestimonialsProps {
  testimonials: Testimonial[];
}

const Testimonials: React.FC<TestimonialsProps> = ({ testimonials }) => {
  const [testimonial, setTestimonial] = useState<Testimonial | undefined>(undefined);

  const handleSetTestimonial = (e: React.MouseEvent, testimonial: Testimonial | undefined) => {
    e.preventDefault();
    document.querySelector('.vrt-edubox__testimonial-hero')!.style.display = testimonial ? 'none' : 'block';

    if (testimonial) {
      document.querySelector('.vrt-edubox__splash')!.style.paddingTop = '90px';
      document.querySelector('.vrt-edubox__splash')!.style.backgroundImage = '';
    } else {
      document.querySelector('.vrt-edubox__splash')!.style.paddingTop = '';
      document.querySelector('.vrt-edubox__splash')!.style.backgroundImage = '';
    }

    setTestimonial(testimonial);
  };

  const getInitials = (string: string) => {
    return string.match(/(\b\S)?/g)?.join('').toUpperCase() || '';
  };

  const image = testimonial?.attributes?.image?.data?.attributes?.formats?.large?.url;
  const authorImage = testimonial?.attributes?.authorImage?.data?.attributes?.formats?.thumbnail?.url;

  return (
    <div>
      {testimonial && (
        <div className="vrt-edubox__testimonial-detail grid-x">
          <div className="cell grid-x large-11">
            <a className="vrt-edubox__testimonial-back" href="#" onClick={(e) => handleSetTestimonial(e, undefined)}>
              overzicht
            </a>
            <div className="vrt-edubox__testimonial-header" style={{ background: `url(${image}) center/cover` }}>
              {/*<div className='vrt-edubox__author-avatar'
                         style={authorImage && {background: `url(${authorImage}) center/cover`}}>{!authorImage && getInitials(testimonial.attributes?.author)}</div>*/}
              <h1 className="vrt-edubox__testimonial-title">{testimonial.attributes?.title}</h1>
            </div>
            <div className="cell large-12">
              <div className="vrt-edubox__author-header">{testimonial.attributes?.author}</div>
            </div>
            <div className="cell large-8 vrt-edubox__content">
              <ReactMarkdown>{testimonial.attributes?.text || ''}</ReactMarkdown>
              <br />
              <br />
              {testimonial.attributes?.youtube && (
                <iframe
                  width="560"
                  height="315"
                  src={`https://www.youtube.com/embed/${testimonial.attributes.youtube}`}
                  title="YouTube video"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              )}
            </div>
            <div className="cell large-offset-1 large-3">
              <div className="vrt-edubox__author-bio">
                <ul>
                  {testimonial.attributes?.authorOccupation?.split(',').map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="cell large-12">
              <Quotes testimonials={[testimonial]} />
            </div>
          </div>
        </div>
      )}
      {!testimonial &&
        testimonials.map(testimonial => {
          const image = testimonial.attributes?.image?.data?.attributes?.formats?.medium?.url;
          const authorImage = testimonial.attributes?.authorImage?.data?.attributes?.formats?.thumbnail?.url;

          return (
            <div className="swiper-slide" key={testimonial.id}>
              <a
                href={`${document.querySelector('[data-page="verklaringen"]')?.href}#${testimonial.id}`}
                onClick={(e) => handleSetTestimonial(e, testimonial)}
              >
                <div className="vrt-edubox__testimonial">
                  <div className="vrt-edubox__testimonial-bg" style={{ background: `url(${image}) center/cover` }}>
                    {/*<div className='vrt-edubox__author-avatar'
                             style={authorImage && {background: `url(${authorImage}) center/cover`}}>{!authorImage && getInitials(testimonial.attributes?.author)}</div>*/}
                  </div>
                  <div className="vrt-edubox__testimonial-content">
                    <h3 className="vrt-edubox__title">{testimonial.attributes?.title}</h3>
                    <div className="vrt-edubox__author">{testimonial.attributes?.author}</div>
                    <div className="vrt-edubox__description">{testimonial.attributes?.authorQuote}</div>
                  </div>
                </div>
              </a>
            </div>
          );
        })}
    </div>
  );
};

export default Testimonials;