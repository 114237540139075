import React from "react";

const Footer = () => {
  return (
    <div className="grid-x grid--blue grid--edge">
      <div className="cell large-2 large-offset-1 text-center large-text-left">
        <br/>
        <h2 className="vrt-edubox__subtitle">Contact</h2>
        <a href="mailto:edubox@vrtnws.be" className="vrt-edubox__link vrt-edubox__link--white">
          <i className="fa fa-envelope"></i> edubox@vrtnws.be
        </a>
      </div>
      <div className="cell large-6 text-center">
        <br/>
        <h2 className="vrt-edubox__subtitle">Volg ons op sociale media</h2>
        <div className="vrt-edubox__social">
          <a target="_blank" href="https://www.facebook.com/vrtvoorleerkrachten" rel="noopener noreferrer">
            <i className="fa fa-facebook"></i>
          </a>
          <a target="_blank" href="https://medium.com/eduboxvrt" rel="noopener noreferrer">
            <i className="fa fa-medium"></i>
          </a>
          <a target="_blank" href="https://www.instagram.com/vrtvoorleerkrachten/" rel="noopener noreferrer">
            <i className="fa fa-instagram"></i>
          </a>
          <a target="_blank" href="https://www.youtube.com/channel/UCkgb9YcOf3-J1HbELmFmkFQ/featured" rel="noopener noreferrer">
            <i className="fa fa-youtube"></i>
          </a>
        </div>
        <br/><br/>
      </div>
      <div className="cell large-3 text-center"></div>
    </div>
  );
};

export default Footer;