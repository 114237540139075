import React from 'react';
import { jezus, toSlug } from './tools';

interface BoxContent {
  hide: string;
  meta: {
    filters: string[];
    image: string;
    color: string;
    intro: string;
    tags: string[];
    splash: string;
    links: {
      self: { href: string };
      generic?: { href: string; label: string };
      order?: { href: string };
      manual?: { href: string };
      pdf?: { href: string };
      site?: { href: string };
      video?: { href: string };
    };
    related?: string[];
    platforms?: { link: { href: string }; image?: { href: string }; title: string }[];
  };
  language: string;
  title: string;
}

interface Box {
  slug: string;
  content: BoxContent[];
}

interface EduboxProps {
  boxes: Box[];
}

interface EduboxState {
  box: Box | null;
  active: number;
  boxes: Box[];
  language: string;
  isExpanded: boolean;
  filter: string;
  filters: string[];
}

class Edubox extends React.Component<EduboxProps, EduboxState> {
  constructor(props: EduboxProps) {
    super(props);

    const boxes = this.props.boxes.filter(box => parseInt(box.content[0].hide) === 0);
    const filters = boxes
      .map(box => box.content[0].meta.filters)
      .flat()
      .filter((v, i, a) => a.indexOf(v) === i)
      .filter(filter => filter);
    const slug = window.location.hash.substr(1);

    const activeBox = boxes.find(box => box.slug === slug) || null;
    const activeFilter = filters.find(filter => toSlug(filter) === slug) || 'all';

    const language = activeBox?.content[0].language || 'NL';

    const activeIndex = activeBox ? boxes.findIndex(box => box.slug === activeBox.slug) : -1;

    this.state = {
      box: activeBox,
      active: activeIndex >= 0 ? activeIndex : 0,
      boxes: [],
      language: language,
      isExpanded: !activeBox,
      filter: activeFilter,
      filters
    };
  }

  getLinks(links: BoxContent['meta']['links'] | undefined) {
    switch (this.state.language) {
      case 'NL':
        return links?.dutch;
      case 'FR':
        return links?.french;
      case 'EN':
        return links?.english;
      case 'DE':
        return links?.german;
      default:
        return undefined;
    }
  }

  getCopy() {
    const copy = {
      dutch: {
        moreInfo: 'meer info',
        orderTheEdubox: 'bestel de edubox',
        orderTheEduboxes: 'bestel de eduboxen',
        readTheManual: 'lees de handleiding',
        downloadThePDF: 'download de pdf',
        downloadThePDFText: 'Download en print zelf de integrale EDUbox',
        openInteractiveSite: 'open de interactieve site',
        openInteractiveSiteText: 'Doorloop de EDUbox online met geïntegreerde video’s en weblinks.',
        watchInteractiveVideo: 'bekijk de interactieve video',
        watchInteractiveVideoText: 'In de videoles treedt de leerling in interactie met een VRT-journalist. Deze kan individueel gevolgd worden.',
        inGroup: 'in groep',
        individual: 'individueel',
        multipleLanguages: 'Ook beschikbaar in andere talen',
        alsoOnLearningplatforms: 'Ook beschikbaar op leerplatformen',
        otherFormatsText: 'Deze edubox is ook beschikbaar in andere formats',
        relatedBoxes: 'Gerelateerde eduboxen'
      },
      french: {
        moreInfo: "plus d'infos",
        orderTheEdubox: "commander l'edubox",
        orderTheEduboxes: "commander les eduboxes",
        readTheManual: "lire le manuel",
        downloadThePDF: "télécharger le pdf",
        downloadThePDFText: "Téléchargez et imprimez vous-même l'EDUbox intégrale",
        openInteractiveSite: "ouvrir le site interactif",
        openInteractiveSiteText: "Parcourez l'EDUbox en ligne avec des vidéos et des liens Web intégrés.",
        watchInteractiveVideo: "regarder la vidéo interactive",
        watchInteractiveVideoText: "Dans la leçon vidéo, l'élève interagit avec un journaliste de la VRT. Cela peut être suivi individuellement.",
        inGroup: "en groupe",
        individual: "individuel",
        multipleLanguages: "Également disponible dans d'autres langues",
        alsoOnLearningplatforms: "Également disponible sur les plateformes d'apprentissage",
        otherFormatsText: "Cette edubox est également disponible dans d'autres formats",
        relatedBoxes: "Eduboxes associées"
      },
      english: {
        moreInfo: 'more info',
        orderTheEdubox: 'order the edubox',
        orderTheEduboxes: 'order the eduboxes',
        readTheManual: 'read the manual',
        downloadThePDF: 'download the pdf',
        downloadThePDFText: 'Download and print the EDUbox yourself',
        openInteractiveSite: 'open the interactive site',
        openInteractiveSiteText: 'Go through the EDUbox online with integrated videos and web links.',
        watchInteractiveVideo: 'watch the interactive video',
        watchInteractiveVideoText: 'In the video lesson, the student interacts with a VRT journalist. This can be followed individually.',
        inGroup: 'in group',
        individual: 'individual',
        multipleLanguages: 'Also available in other languages',
        alsoOnLearningplatforms: 'Also available on learning platforms',
        otherFormatsText: 'This edubox is also available in other formats',
        relatedBoxes: 'Related eduboxes'
      },
      german: {
        moreInfo: 'Mehr Info',
        orderTheEdubox: 'edubox bestellen',
        orderTheEduboxes: 'die eduboxen bestellen',
        readTheManual: 'Lesen Sie das Handbuch',
        downloadThePDF: 'pdf herunterladen',
        downloadThePDFText: 'Laden Sie die integrale EDUbox herunter und drucken Sie sie selbst aus',
        openInteractiveSite: 'interaktive Seite öffnen',
        openInteractiveSiteText: 'Durchsuchen Sie die EDUbox online mit integrierten Videos und Weblinks.',
        watchInteractiveVideo: 'interaktives Video ansehen',
        watchInteractiveVideoText: 'In der Videolektion interagiert der Schüler mit einem VRT-Journalisten. Dies kann individuell verfolgt werden.',
        inGroup: 'in einer Gruppe',
        individual: 'Individuell',
        multipleLanguages: 'Auch in anderen Sprachen erhältlich',
        alsoOnLearningplatforms: 'Auch auf Lernplattformen verfügbar',
        otherFormatsText: 'Diese Edubox ist auch in anderen Formaten erhältlich',
        relatedBoxes: 'Verwandte Eduboxen'
      }
    };

    switch (this.state.language) {
      case 'NL':
        return copy.dutch;
      case 'FR':
        return copy.french;
      case 'EN':
        return copy.english;
      case 'DE':
        return copy.german;
      default:
        return copy.dutch;
    }
  }

  render() {
    const boxes = this.props.boxes
      .filter(box => parseInt(box.content[0].hide) === 0 && box.content[0].language === this.state.language)
      .filter(box => this.state.filter !== 'all' ? box.content[0].meta.filters?.includes(this.state.filter) : true);
    const meta = this.state.box?.content[0].meta;
    const links = this.getLinks(meta?.links);
    const copy = this.getCopy();

    const setBox = (box: Box) => {
      this.setState({
        box: box,
        isExpanded: false
      }, () => window.scrollTo(0, 0));
    }

    const getButton = (href: string) => {
      const hrefs = href.split(';');

      if (hrefs.length > 1) {
        return (
          <button className="vrt-edubox__button-group vrt-edubox__button--highlight">
            <span className="vrt-edubox__button-label">{copy.orderTheEduboxes}</span>
            <span className="vrt-edubox__button-label vrt-edubox__button-icon">
                            <i className="fa fa-shopping-basket" aria-hidden="true" />
                        </span>
            <span className="vrt-edubox__button-wrapper">
                            {hrefs.map((href, index) => (
                              <a key={index} href={href.split(',')[1]} className="vrt-edubox__button vrt-edubox__button--highlight">
                                <span className="vrt-edubox__button-label">{href.split(',')[0]}</span>
                              </a>
                            ))}
                        </span>
          </button>
        );
      } else {
        return hrefs.map((href, index) => (
          <a key={index} href={href} className="vrt-edubox__button vrt-edubox__button--highlight">
            <span className="vrt-edubox__button-label">{copy.orderTheEdubox}</span>
            <span className="vrt-edubox__button-label vrt-edubox__button-icon">
                            <i className="fa fa-shopping-basket" aria-hidden="true" />
                        </span>
          </a>
        ));
      }
    }

    const toggle = (b?: boolean) => {
      if (!this.state.isExpanded) {
        window.location.hash = '#';
      }

      this.setState({
        isExpanded: b ?? !this.state.isExpanded
      });
    }

    const setLang = (l: string) => {
      this.setState({
        language: l
      });
    }

    const setFilter = (f: string) => {
      this.setState({
        filter: f
      });

      window.location.hash = toSlug(f);
    }

    return (
      <>
        <div>
          <div className="grid-x">
            <div className="cell large-12">
              <div className={`vrt-edubox__boxes ${this.state.isExpanded ? 'js-expanded' : ''}`}>
                {!this.state.isExpanded && (
                  <button className="vrt-edubox__expand" onClick={() => toggle()}>
                    <i className="fa fa-th" aria-hidden="true"></i> overzicht
                  </button>
                )}
                <div className="vrt-edubox__filter">
                  <div>
                    filter:
                    <button className={`vrt-edubox__filter-item ${this.state.filter === 'all' ? 'js-active' : ''}`} onClick={() => setFilter('all')}>
                      alles
                    </button>
                    {this.state.filters.map(filter => (
                      <button key={filter} className={`vrt-edubox__filter-item ${this.state.filter === filter ? 'js-active' : ''}`} onClick={() => setFilter(filter)}>
                        {filter}
                      </button>
                    ))}
                  </div>
                  <div>
                    taal:
                    <button className={`vrt-edubox__filter-item ${this.state.language === 'NL' ? 'js-active' : ''}`} onClick={() => setLang('NL')}>
                      NL
                    </button>
                    <button className={`vrt-edubox__filter-item ${this.state.language === 'FR' ? 'js-active' : ''}`} onClick={() => setLang('FR')}>
                      FR
                    </button>
                    <button className={`vrt-edubox__filter-item ${this.state.language === 'EN' ? 'js-active' : ''}`} onClick={() => setLang('EN')}>
                      EN
                    </button>
                    <button className={`vrt-edubox__filter-item ${this.state.language === 'DE' ? 'js-active' : ''}`} onClick={() => setLang('DE')}>
                      DE
                    </button>
                  </div>
                </div>
                <ul className="vrt-edubox__boxes-list">
                  {boxes.map(box => (
                    <li key={`box${box.slug}`} className={`vrt-edubox__boxes-item compile-boxes-thumbs ${this.state.box === box ? 'js-active' : ''}`} onClick={() => setBox(box)}>
                      <a href={`#${box.slug}`}>
                        <img className="vrt-edubox__image" src={box.content[0].meta.image} alt="" />
                        <div className="vrt-edubox__image-overlay" style={{ background: box.content[0].meta.color }}></div>
                        <span className="vrt-edubox__label">{box.content[0].title}</span>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          {!this.state.isExpanded && this.state.box && (
            <section
              className="vrt-edubox__section vrt-edubox__section--box margin-bottom-2"
              style={{
                background: `linear-gradient(45deg, rgba(0,0,0,0.7) 30%, transparent 70%), url(${this.state.box.content[0].meta.splash}) black center/cover`
              }}>
              <div className="grid-x">
                <div className="cell large-12">
                  <h1 className="vrt-edubox__title">{this.state.box.content[0].title}</h1>
                  <style>
                    {`.vrt-edubox__boxes::-webkit-scrollbar-thumb {
                                            background: ${meta?.color}
                                        }`}
                  </style>

                  <div className="vrt-edubox__labels">
                    {meta?.tags.map(tag => (
                      <span key={tag} className="vrt-edubox__link" style={{ background: meta.color }}>
                                                {tag}
                                            </span>
                    ))}
                  </div>
                  <div className="vrt-edubox__subtitle">{meta?.intro}</div>
                  {jezus(() => links?.self.href) && (
                    <div className="vrt-edubox__more">
                      <a href={links?.self.href} className="vrt-edubox__link">
                        {copy.moreInfo} <i className="fa fa-link" />
                      </a>
                    </div>
                  )}
                  <div className="vrt-edubox__perspective">
                    {jezus(() => links?.generic?.href) && jezus(() => links?.generic?.label) && (
                      <a href={links?.generic.href} className="vrt-edubox__button">
                        <span className="vrt-edubox__button-label">{links?.generic.label}</span>
                      </a>
                    )}
                    {jezus(() => links?.order?.href) && getButton(links?.order.href)}
                    {jezus(() => links?.manual?.href) && (
                      <a href={links?.manual.href} className="vrt-edubox__button">
                        <span className="vrt-edubox__button-label">{copy.readTheManual}</span>
                        <span className="vrt-edubox__button-label vrt-edubox__button-icon">
                                                    <i className="fa fa-book" aria-hidden="true" />
                                                </span>
                      </a>
                    )}
                  </div>
                </div>
              </div>
              <div className="grid-x">
                <div className="cell large-12">
                  <div className="vrt-edubox__formats">{copy.otherFormatsText}:</div>
                </div>
              </div>
              <div className="grid-x margin-bottom-3">
                {jezus(() => links?.pdf?.href) && (
                  <div className="cell large-3 padding-top-1 padding-right-1">
                    <div className="vrt-edubox__format">
                      <i className="fa fa-users" /> {copy.inGroup} <br />
                      <a href={links?.pdf.href} className="vrt-edubox__button">
                        <span className="vrt-edubox__button-label">{copy.downloadThePDF}</span>
                        <span className="vrt-edubox__button-label vrt-edubox__button-icon">
                                                    <i className="fa fa-download" aria-hidden="true" />
                                                </span>
                      </a>
                      <br />
                      {copy.downloadThePDFText}
                    </div>
                  </div>
                )}
                {jezus(() => links?.site?.href) && (
                  <div className="cell large-3 padding-top-1 padding-right-1">
                    <div className="vrt-edubox__format">
                      <i className="fa fa-users" /> {copy.inGroup} <br />
                      <a href={links?.site.href} className="vrt-edubox__button">
                        <span className="vrt-edubox__button-label">{copy.openInteractiveSite}</span>
                        <span className="vrt-edubox__button-label vrt-edubox__button-icon">
                                                    <i className="fa fa-link" aria-hidden="true" />
                                                </span>
                      </a>
                      <br />
                      {copy.openInteractiveSiteText}
                    </div>
                  </div>
                )}
                {jezus(() => links?.video?.href) && (
                  <div className="cell large-3 padding-top-1 padding-right-1">
                    <div className="vrt-edubox__format">
                      <i className="fa fa-user" /> {copy.individual} <br />
                      <a href={links?.video.href} className="vrt-edubox__button">
                        <span className="vrt-edubox__button-label">{copy.watchInteractiveVideo}</span>
                        <span className="vrt-edubox__button-label vrt-edubox__button-icon">
                                                    <i className="fa fa-film" aria-hidden="true" />
                                                </span>
                      </a>
                      <br />
                      {copy.watchInteractiveVideoText}
                    </div>
                  </div>
                )}
                {jezus(() => meta?.links?.french?.self?.href || meta?.links?.english?.self?.href) && (
                  <div className="cell large-2 padding-right-1">
                    {copy.multipleLanguages}:
                    <br />
                    {jezus(() => meta?.links?.dutch?.self?.href) && this.state.language !== 'NL' && (
                      <a
                        href="#"
                        onClick={() => {
                          window.location.href = meta!.links.dutch.self.href;
                          window.location.reload();
                        }}
                        className="vrt-edubox__button vrt-edubox__button--less">
                        <span className="vrt-edubox__button-label">nederlands</span>
                        <span className="vrt-edubox__button-label vrt-edubox__button-icon">
                                                <i className="fa fa-link" aria-hidden="true" />
                                            </span>
                      </a>
                    )}
                    {jezus(() => meta?.links?.french?.self?.href) && this.state.language !== 'FR' && (
                      <a
                        href="#"
                        onClick={() => {
                          window.location.href = meta!.links.french.self.href;
                          window.location.reload();
                        }}
                        className="vrt-edubox__button vrt-edubox__button--less">
                        <span className="vrt-edubox__button-label">francais</span>
                        <span className="vrt-edubox__button-label vrt-edubox__button-icon">
                                                <i className="fa fa-link" aria-hidden="true" />
                                            </span>
                      </a>
                    )}
                    {jezus(() => meta?.links?.english?.self?.href) && this.state.language !== 'EN' && (
                      <a
                        href="#"
                        onClick={() => {
                          window.location.href = meta!.links.english.self.href;
                          window.location.reload();
                        }}
                        className="vrt-edubox__button vrt-edubox__button--less">
                        <span className="vrt-edubox__button-label">english</span>
                        <span className="vrt-edubox__button-label vrt-edubox__button-icon">
                                                <i className="fa fa-link" aria-hidden="true" />
                                            </span>
                      </a>
                    )}
                  </div>
                )}
                {jezus(() => meta?.related && meta?.related.length > 0) && (
                  <div className="cell large-3 padding-top-1 padding-right-1">
                    {copy.relatedBoxes}:
                    <br />
                    {meta?.related.map(box => (
                      <a
                        key={box}
                        href="#"
                        onClick={() => {
                          window.location.hash = box;
                          window.location.reload();
                        }}
                        className="vrt-edubox__button vrt-edubox__button--less">
                        <span className="vrt-edubox__button-label">#{box}</span>
                        <span className="vrt-edubox__button-label vrt-edubox__button-icon">
                                                    <i className="fa fa-link" aria-hidden="true" />
                                                </span>
                      </a>
                    ))}
                  </div>
                )}
              </div>
              <div className="grid-x margin-bottom-3">
                {meta?.platforms && meta.platforms.length > 0 && meta.platforms[0].link && (
                  <div className="cell large-2 padding-right-1">
                    {copy.alsoOnLearningplatforms}:
                    <br />
                    {meta.platforms.map(platform => (
                      platform.link &&
                      <a
                        key={platform.title}
                        href={platform.link.href}
                        target="_blank"
                        className="vrt-edubox__leerplatform">
                        {platform.image && <img src={platform.image.href} alt={platform.title} />}
                      </a>
                    ))}
                  </div>
                )}
              </div>
            </section>
          )}
          {!this.state.isExpanded && meta?.images && meta.images.length > 0 && (
            <section className="vrt-edubox__section--sfeer margin-top-2 margin-bottom-2 padding-1">
              <div className="grid-x grid-margin-x grid-margin-y">
                <div className="cell large-12">
                  <h2 className="vrt-edubox__title vrt-edubox__title--light">Sfeerbeelden</h2>
                </div>
              </div>
              <div className="grid-x grid-margin-x grid-margin-y">
                <div className="cell large-6">
                  {meta.images[0] && <img src={meta.images[0]} alt="" />}
                </div>
                <div className="cell large-3">
                  {meta.images[1] && <img src={meta.images[1]} alt="" />}
                  {meta.images[2] && <img src={meta.images[2]} alt="" />}
                </div>
                <div className="cell large-3">
                  {meta.images[3] && <img src={meta.images[3]} alt="" />}
                  {meta.images[4] && <img src={meta.images[4]} alt="" />}
                </div>
              </div>
              <div className="grid-x grid-margin-x grid-margin-y">
                <div className="cell large-3">
                  {meta.images[5] && <img src={meta.images[5]} alt="" />}
                  {meta.images[6] && <img src={meta.images[6]} alt="" />}
                </div>
                <div className="cell large-3">
                  {meta.images[7] && <img src={meta.images[7]} alt="" />}
                  {meta.images[8] && <img src={meta.images[8]} alt="" />}
                </div>
                <div className="cell large-6">
                  {meta.images[9] && <img src={meta.images[9]} alt="" />}
                </div>
              </div>
            </section>
          )}
          {!this.state.isExpanded && meta?.partners && meta.partners.length > 0 && (
            <section className="vrt-edubox__section--partners margin-top-2 margin-bottom-2 padding-1">
              <div className="cell grid-x">
                <div className="cell large-12 text-center">
                  <h2 className="vrt-edubox__title vrt-edubox__title--light">Partners</h2>
                  <div className="grid-x align-bottom">
                    {meta.partners.map(partner => (
                      <div key={partner.title} className="cell large-4 text-center">
                        <a className="vrt-edubox__link" target="_blank" href={partner.link.href}>
                          {partner.image && <img src={partner.image.href} alt={partner.title} className="css-grey" />}
                        </a>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </section>
          )}
        </div>
      </>
    );
  }
}

export default Edubox;