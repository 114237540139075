import React, { useEffect } from 'react';
import Swiper from 'swiper';
import moment from 'moment';

interface EventAttributes {
  link?: string;
  image?: {
    data?: {
      attributes?: {
        formats?: {
          medium?: {
            url?: string;
          };
        };
      };
    };
  };
  title?: string;
  startDate?: string;
  description?: string;
  highlight?: boolean;
}

interface Event {
  attributes?: EventAttributes;
}

interface EventsProps {
  events: Event[];
  highlight?: boolean;
}

const Events: React.FC<EventsProps> = ({ events, highlight }) => {
  useEffect(() => {
    const swiper = new Swiper('.swiper-events', {
      direction: 'vertical',
      spaceBetween: 70,
      autoplay: {
        delay: 5000,
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
    });
  }, []);

  const template = (event: Event) => (
    <div className="swiper-slide" key={event.attributes?.link}>
      <a href={event.attributes?.link} target="_blank" rel="noopener noreferrer">
        <div className="vrt-edubox__event">
          <div
            className="vrt-edubox__event-bg"
            style={{
              background: `url(${event.attributes?.image?.data?.attributes?.formats?.medium?.url}) center/cover`,
            }}
          ></div>
          <div className="vrt-edubox__event-blob"></div>
          <div className="vrt-edubox__event-content">
            <h3 className="vrt-edubox__title">{event.attributes?.title}</h3>
            {event.attributes?.startDate && (
              <time className="vrt-edubox__date" dateTime={event.attributes?.startDate}>
                {moment(event.attributes?.startDate).format('DD/MM')}
              </time>
            )}
            <div className="vrt-edubox__description">{event.attributes?.description}</div>
          </div>
        </div>
      </a>
    </div>
  );

  if (highlight) {
    return (
      <>
        {events
          .filter(event => event.attributes?.highlight)
          .map(event => template(event))}
      </>
    );
  } else {
    const now = new Date().getTime();

    return (
      <div>
        {events
          .filter(event => new Date(event.attributes?.startDate!).getTime() > now)
          .map(event => template(event))}
        <h2>Afgelopen evenementen</h2>
        {events
          .filter(event => new Date(event.attributes?.startDate!).getTime() < now)
          .map(event => template(event))}
      </div>
    );
  }
};

export default Events;