import getEnv from "@/env/env.tsx";

interface Box {
  // Define the structure of a Box
}

interface Event {
  // Define the structure of an Event
}

interface Testimonial {
  id: number;
  attributes: {
    order: number;
    // Add other required attributes here
  };
}

class Service {
  getBoxes = async (): Promise<Box[]> => {
    const response = await fetch('/nl/api/edubox/boxes');


    if (!response.ok) {
      throw new Error('Failed to fetch boxes');
    }

    return await response.json();
  }

  getEvents = async (): Promise<{ data: Event[] }> => {
    const response = await fetch('/nl/api/edubox/events');

    if (!response.ok) {
      throw new Error('Failed to fetch events');
    }

    return response.json();
  }

  getTestimonials = async (): Promise<{ data: Testimonial[] }> => {
    const response = await fetch('/nl/api/edubox/testimonials');

    if (!response.ok) {
      throw new Error('Failed to fetch testimonials');
    }

    const json = await response.json();
    const ordered = json.data.sort((a: Testimonial, b: Testimonial) => b.attributes.order - a.attributes.order);
    return { data: ordered };
  }
}

export default new Service();