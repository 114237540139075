
/**
 * Converts a string to a URL-friendly slug.
 * @param str - The input string to be converted.
 * @returns The slugified string.
 */
const toSlug = (str: string): string =>
  str
    .toString()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '') // Remove diacritics
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w-]+/g, '') // Remove all non-word characters
    .replace(/--+/g, '-'); // Replace multiple - with single -

/**
 * Safely executes a function and returns a fallback value if an error occurs.
 * @param func - The function to execute.
 * @param fallback - The fallback value to return in case of an error.
 * @returns The result of the function or the fallback value.
 */
const jezus = <T>(func: () => T, fallback: T | undefined = undefined): T | undefined => {
  try {
    return func();
  } catch (e) {
    return fallback;
  }
};

export {
  toSlug,
  jezus
};